import React, { useState, useEffect } from "react";
import SectionCard from "./SectionCard";
import {useNavigate} from "react-router-dom";
import {setAdvFooter} from "./WorkFunctions";

const Sections = () => {
    const [sections, setSections] = useState([]);
    const navigate = useNavigate();
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        fetch("content/sections.json")
            .then(response => response.json())
            .then((data) => {
                setSections(data);
            })
            .catch(error => {
                setIsError(true);
            });
    }, []);
    useEffect(() => {
        window.scrollTo(0, 0);
        setAdvFooter();
    });
    useEffect(() => {
        if (isError) {
            navigate(`/error`);
        }
    });
    return (
        <><link rel="stylesheet" href="/main.css"/>
        <section id="hero">
            <h2>Туториалы по программированию</h2>
            <p>Изучайте программирование с нашими удобными и информативными туториалами.</p>
        </section>
    <section id="tutorials">
        {sections.map((section, index) => (
            <SectionCard
                key={index}
                title={section.title}
                    description={section.description}
                    url={section.url}
                />
            ))}
        </section>
            </>
    );
};

export default Sections;
