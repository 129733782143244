import React, {useEffect, useState} from "react";

const Theme = () => {
    const [theme, setTheme] = useState(() => {
        return localStorage.getItem('theme') || 'dark';
    });
    useEffect(() => {
        if (theme === "dark") {
            document.getElementById("toggleSwitch").classList.add("on");
        }
    })

    if (theme === 'dark') {
        return (
            <>
                <link rel="stylesheet" href="/light.css" id="light-theme" disabled/>
                <link rel="stylesheet" href="/dark.css" id="dark-theme"/>
            </>
        );
    }
    return (
        <>
            <link rel="stylesheet" href="/dark.css" id="dark-theme" disabled/>
            <link rel="stylesheet" href="/light.css" id="light-theme"/>
        </>
    )
}
export default Theme;