import React, {useEffect, useState} from "react";
import SectionCard from "./SectionCard";
import {useNavigate, useParams} from "react-router-dom";
import "./sections.css"
import {setAdvFooter} from "./WorkFunctions";

const Tutorials = () => {
    const {section} = useParams();
    const [isError, setIsError] = useState(false);
    const [tutorials, setTutorials] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        fetch(`/content/${section}/${section}.json`)
            .then(response => response.json())
            .then(data => {
                setTutorials(data);
            })
            .catch(error => {
                setIsError(true);
            })
    }, [section]);
    useEffect(() => {
        window.scrollTo(0, 0);
        setAdvFooter();
    });
    useEffect(() => {
        if (isError) {
            navigate(`/error`);
        }
    })
    return (
        <section id="tutorials">
            {tutorials.map((tutorial, index) => (
                <SectionCard key={index} title={tutorial.title} description={tutorial.description}
                             url={tutorial.url}></SectionCard>
            ))}
        </section>
    )
}
export default Tutorials;