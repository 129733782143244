import React, {useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import ReactMarkdown from "react-markdown";
import hljs from "highlight.js";
import {
    ClearFullCode,
    RemoveClipBoardButtons,
    setAdv, setAdvFooter,
    setAdvPageNav,
    setClipboardButton
} from "./WorkFunctions";
import PageNav from "./PagesNav";
import remarkGfm from "remark-gfm";

const TableWrapper = ({ children }) => (
    <div className="table-render">
        <table>{children}</table>
    </div>
);




const Article = () => {
    const {section, tutorial, article} = useParams();
    const [text, setText] = useState("");
    const [fUrl, setFUrl] = useState("no-url");
    const [bUrl, setBUrl] = useState("no-url");
    const [isError, setIsError] = useState(false);
    const navigate = useNavigate();
    const containerRef = useRef(null);


    useEffect(() => {
        fetch(`/content/${section}/${tutorial}/articles.json`)
            .then(response => response.json())
            .then(articles => {
                setFUrl("no-url");
                setBUrl("no-url");
                let index = articles.findIndex(art => art.name === article);
                if (index > 0) {
                    setBUrl(`${articles[index - 1].url}`);
                }
                if (index < articles.length - 1) {
                    setFUrl(`${articles[index + 1].url}`);
                }
            })
            .catch(error => setIsError(true));
    })
    useEffect(() => {
        fetch(`/content/${section}/${tutorial}/${article}.md`)
            .then(response => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.text();
            })
            .then(data => {
                setText(data);
            })
            .catch(error => {
                setIsError(true);
            });
    }, [section, tutorial, article]);
    useEffect(() => {
        window.scrollTo(0, 0);
    });
    useEffect(() => {
        if (isError) {
            navigate(`/error`);
        }
    });
    useEffect(() => {
        ClearFullCode();
        hljs.highlightAll();
        RemoveClipBoardButtons();
        setClipboardButton();
        setAdv();
        setAdvPageNav();
        setAdvFooter();
    })

    return (
        <div ref={containerRef}>
            <PageNav forwardLink={fUrl} backLink={bUrl} sectionUrl={`/tutorials/${section}`}/>
            <ReactMarkdown remarkPlugins={[remarkGfm]} components={{
                table: (props) => <TableWrapper {...props} />
            }}>{text}</ReactMarkdown>
            <PageNav forwardLink={fUrl} backLink={bUrl} sectionUrl={`/tutorials/${section}`}/>
        </div>
    );
};

export default Article;
