import ReactDOM from 'react-dom/client';
import './index.css';
import React from "react";
import App from "./components/App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <App />
);


