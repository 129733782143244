import Theme from "./Theme";
import {HashRouter as Router, Route, Routes} from "react-router-dom";
import Header from "./Header";
import Article from "./Article";
import Tutorials from "./Tutorials";
import Sections from "./Content";
import ErrorPage from "./ErrorPage";
import Footer from "./Footer";
import React from "react";
import "../index.css"

const App = () => {
    return (
        <>
            <Theme />
            <Router>
                <Header url={"/"}/>
                <main className="main-content">
                    <aside className="adv-side">
                    </aside>
                    <article className="main-content-text">
                        <Routes>
                            <Route path="/tutorials/:section/:tutorial/:article" element={<Article/>}></Route>
                            <Route path="/tutorials/:section" element={<Tutorials/>}></Route>
                            <Route path="/" element={<Sections/>}></Route>
                            <Route path="*" element={<ErrorPage/>}></Route>
                        </Routes>
                    </article>
                    <aside className="adv-side">
                    </aside>
                </main>
            </Router>
            <Footer />
        </>
    )
}
export default App;