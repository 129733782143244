import React, {useEffect} from "react";
const Footer = () => {
    return (
        <footer>
            <p>
                <a href="https://t.me/+qce_aMn5dRk1NGIy">Телеграм</a>
            </p>
            <p>&copy; 2024 PROGVIBE.RU Все права защищены</p>
        </footer>
    )
}
export default Footer;