import React from "react";
import {Routes, Route, Link} from "react-router-dom";
import "../index";
import ThemeToggler from "./ThemeToggler";
import Menu from "./Menu";

const Header = (props) => {
    return (
        <header>
            <div className="header-container">
                    <Routes>
                        <Route path="/tutorials/:section/:tutorial/:article" element={<Menu/>}></Route>
                        <Route path="/tutorials/:section"></Route>
                        <Route path="/"></Route>
                    </Routes>
                <Link to="/" className="header">PROGVIBE</Link>
            </div>
            <ThemeToggler />
        </header>

    )
}
export default Header;