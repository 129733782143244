import React from "react";

const ErrorPage = () => {
    return (
        <div style={{textAlign: "center"}}>
            <h1>404</h1>
            <p>Ой! Страница не найдена.</p>
            <p>Кажется, мы не можем найти страницу, которую вы ищете.</p>
            <a href="/public">Вернуться на главную</a>
        </div>
    )
}
export default ErrorPage;